<template>
  <Layout>
    <div class="app-pinlist">
      <div class="tool">
        <a-form layout="inline">
          <a-button @click="add" type="primary">
            <a-icon type="file-add" /> 新建拼团活动
          </a-button>

          <a-button @click="showRule" type="link" icon="setting">
            配置规则
          </a-button>
        </a-form>

        <a-form layout="inline" :form="form" @submit="handleSearch">
          <a-form-item label="活动状态">
            <a-select
              v-decorator="['status']"
              placeholder="活动状态"
              style="width:100px;"
            >
              <a-select-option :value="0">全部</a-select-option>
              <a-select-option :value="-2">未开始</a-select-option>
              <a-select-option :value="1">进行中</a-select-option>
              <a-select-option :value="-1">已结束</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item>
            <a-input
              v-decorator="['title']"
              :maxLength="20"
              placeholder="商品标题"
            />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit">
              搜索
            </a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="table-wrap">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="pagination"
          :loading="loading"
          @change="handleTableChange"
        >
          <span slot="img" slot-scope="img">
            <img :src="img" class="product-img" />
          </span>
          <span slot="productTitle" slot-scope="title, record">
            <router-link
              :to="{ name: 'PinOrder', query: { pinId: record.id } }"
            >
              {{ title }}
            </router-link>
          </span>
          <span slot="startTime" slot-scope="_, record">
            <a-tag>始</a-tag>{{ record.startTime | formatDate }} <br />
            <a-tag>终</a-tag>{{ record.endTime | formatDate }}
          </span>
          <span slot="endTime" slot-scope="time">{{ time | formatDate }}</span>
          <span slot="createdAt" slot-scope="time">{{
            time | formatDate
          }}</span>
          <span slot="stat" slot-scope="_, record">
            <a-tag>参团人数</a-tag>{{ record.cnt }} <br />
            <a-tag>成团人数</a-tag>{{ record.completeCnt }} <br />
            <a-tag>领券人数</a-tag>{{ record.userCouponCnt }}
          </span>
          <span slot="action" slot-scope="_, record">
            <router-link :to="{ name: 'PinEdit', query: { id: record.id } }">
              编辑
            </router-link>
            <a-divider type="vertical" />
            <a @click="share(record.id)">分享</a>
            <a-divider type="vertical" />
            <a @click="toggleActive(record.id)">{{
              record.active ? "失效" : "启用"
            }}</a>
            <a-divider type="vertical" />
            <a style="color:#f5222d" @click="del(record.id)">删除</a>
          </span>
        </a-table>
      </div>

      <!-- 分享二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="shareDialogVisible"
        @cancel="shareDialogVisible = false"
        class="share-dialog-wrap"
      >
        <div class="title">
          右键保存小程序码即可分享
        </div>
        <img :src="shareInfo.qrcode" class="qrcode" />
        <div class="row">
          <span class="label">AppId：</span>
          <a-input disabled :value="store.id" />
          <a-button @click="copyText(store.id)">复制</a-button>
        </div>
        <div class="row">
          <span class="label">路径：</span>
          <a-input disabled :value="shareInfo.path" />
          <a-button @click="copyText(shareInfo.path)">复制</a-button>
        </div>
        <div class="row">
          <a-icon type="info-circle" />
          提示：请先装修首页并发布，否则会显示「页面不存在」的错误
        </div>
      </a-modal>

      <!-- 配置 -->
      <a-modal
        title="配置规则"
        @ok="submitRule"
        :destroyOnClose="true"
        :visible="ruleDialogVisible"
        @cancel="ruleDialogVisible = false"
      >
        <a-form-model
          :model="rule"
          ref="rule"
          label-align="left"
          :label-col="{ span: 20 }"
          :wrapper-col="{ span: 4 }"
        >
          <a-form-model-item
            required
            label="公开拼团"
            prop="pin_limit_public"
            :rules="[
              {
                required: true
              }
            ]"
          >
            <a-switch v-model="rule.pin_limit_public" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="显示空头像"
            prop="pin_show_left"
            :rules="[
              {
                required: true
              }
            ]"
          >
            <a-switch v-model="rule.pin_show_left" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="同一活动仅限发起一次"
            prop="pin_limit_1_open"
            :rules="[
              {
                required: true
              }
            ]"
          >
            <a-switch v-model="rule.pin_limit_1_open" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="同一活动仅限参加一次"
            prop="pin_limit_1_pin"
            :rules="[
              {
                required: true
              }
            ]"
          >
            <a-switch v-model="rule.pin_limit_1_pin" />
          </a-form-model-item>
          <a-form-model-item
            required
            label="同一活动最多参加几个团"
            prop="pin_limit_join_num"
            :rules="[
              {
                required: true,
                message: '限制人数必填'
              }
            ]"
          >
            <a-input-number
              v-model="rule.pin_limit_join_num"
              :min="1"
              :max="100"
            />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

import { mapState } from "vuex";

const PinSvc = require("@/service/pin");

import wxacodeMixin from "@/mixins/wxacode";

export default {
  name: "PinList",

  data() {
    return {
      status: 0,
      form: this.$form.createForm(this, { name: "form_search" }),

      // 配置
      ruleDialogVisible: false,
      rule: {
        pin_limit_1_open: true,
        pin_limit_1_pin: false,
        pin_limit_join_num: 3,
        pin_limit_public: true,
        pin_show_left: true
      },

      list: [],
      filters: {},
      pagination: {
        showTotal: total => `共 ${total} 条记录`
      },
      loading: false
    };
  },

  mixins: [wxacodeMixin],

  computed: {
    ...mapState({
      store: state => state.store
    }),

    columns() {
      const { filters } = this;

      return [
        {
          title: "图片",
          align: "center",
          dataIndex: "img",
          key: "img",
          width: 60,
          scopedSlots: { customRender: "img" }
        },
        {
          title: "标题",
          dataIndex: "title",
          key: "title",
          scopedSlots: { customRender: "productTitle" },
          filteredValue: filters.title || null
        },
        {
          title: "原价",
          align: "right",
          dataIndex: "price",
          key: "price",
          width: 100
        },
        {
          title: "拼团价",
          align: "right",
          dataIndex: "pinPrice",
          key: "pinPrice",
          width: 100
        },
        {
          title: "有效时间",
          align: "right",
          dataIndex: "expireHour",
          key: "expireHour",
          width: 100,
          customRender: text => `${text}小时`
        },
        {
          title: "成团人数",
          align: "right",
          dataIndex: "num",
          key: "num",
          width: 100
        },
        {
          title: "活动时间",
          align: "center",
          dataIndex: "startTime",
          key: "startTime",
          width: 200,
          scopedSlots: { customRender: "startTime" }
        },
        {
          title: "活动数据",
          dataIndex: "userCnt",
          key: "userCnt",
          width: 140,
          scopedSlots: { customRender: "stat" }
        },
        {
          title: "状态",
          align: "center",
          dataIndex: "status",
          key: "status",
          width: 100,
          filteredValue: filters.status || 0
        },
        {
          title: "操作",
          key: "action",
          width: 220,
          scopedSlots: { customRender: "action" }
        }
      ];
    }
  },

  methods: {
    async showRule() {
      try {
        let {
          pin_limit_1_open,
          pin_limit_1_pin,
          pin_limit_join_num,
          pin_limit_public,
          pin_show_left
        } = await PinSvc.getRule();
        pin_limit_1_open = !!pin_limit_1_open;
        pin_limit_1_pin = !!pin_limit_1_pin;
        pin_limit_public = !!pin_limit_public;
        pin_show_left = !!pin_show_left;
        this.rule = {
          pin_limit_1_open,
          pin_limit_1_pin,
          pin_limit_join_num,
          pin_limit_public,
          pin_show_left
        };
      } catch (err) {
        console.error(err);
      }
      this.ruleDialogVisible = true;
    },
    submitRule() {
      this.$refs.rule.validate(async valid => {
        if (valid) {
          try {
            await PinSvc.updateRule(this.rule);
            this.$message.info("已设置规则");

            this.ruleDialogVisible = false;
          } catch (err) {
            console.error(err);
            this.$message.error("设置规则失败，错误：" + err.message);
          }
        }
      });
    },

    add() {
      this.$router.push({ path: "/sale/pin/edit" });
    },

    async share(id) {
      const path = `plugin-private://wxd14f33852509c129/pages/pinDetail/index?id=${id}`;
      await this.sharePath(path);
    },

    async toggleActive(id) {
      try {
        await PinSvc.updateStatus(id);

        await this.fetch(this.filters);
      } catch (err) {
        console.error(err);
        this.$message.error("切换状态失败，错误：" + err.message);
      }
    },

    async del(id) {
      this.$confirm({
        title: "操作提示",
        content: "确认删除此活动吗？",

        onOk: () => {
          this._delete(id);
        },
        onCancel() {}
      });
    },

    async _delete(id) {
      try {
        await PinSvc.del(id);

        await this.fetch(this.filters);
      } catch (err) {
        console.error(err);
        this.$message.error("删除失败，错误：" + err.message);
      }
    },

    async handleSearch(e) {
      e.preventDefault();
      const values = this.form.getFieldsValue();
      let { title, status } = values;

      title = title || "";
      status = status || 0;
      this.filters = { ...this.filters, title, status };

      await this.fetch(this.filters);
    },

    async handleTableChange(pagination, filters) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.filters = filters;

      const { pageSize, current: page } = pagination;
      await this.fetch({
        pageSize,
        page,
        ...filters
      });
    },

    async fetch(params = {}) {
      this.loading = true;

      try {
        const data = await PinSvc.list({
          page: 1,
          pageSize: 10,
          ...params
        });

        const pagination = {
          ...this.pagination,
          total: data.cnt,
          current: data.page
        };
        this.list = data.res;
        this.pagination = pagination;

        this.loading = false;
      } catch (err) {
        this.loading = false;

        console.error(err);
        this.$message.error("加载数据出错，错误：" + err.message);
      }
    }
  },

  async mounted() {
    await this.fetch();
  },

  components: {
    Layout
  }
};
</script>

<style lang="scss">
.app-pinlist {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }
  .table-wrap {
    background: #fff;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      overflow: hidden;
      border: 1px solid #fff;
    }

    .ant-table-pagination.ant-pagination {
      float: none;
      text-align: center;
    }
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }
}
</style>
